

//launchbutton
.launchBar {
  color: #262D34;
}
.buttonDefault{
  color:white;
}
//footer
footer h3{
  font-size: 12pt;
}